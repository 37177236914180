import { BrowserRouter, Routes, Route } from 'react-router-dom'

// pages & components
import Channels from './pages/Channels'
import Navbar from './components/Navbar'
import MainSlider from './pages/MainSlider';
import ChannelSlides from './pages/ChannelSlides';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="pages">
          <Routes>
            <Route 
              path="/" 
              element={<Channels />} 
            />
            <Route 
              path="/main_slides" 
              element={<MainSlider />} 
            />
            <Route 
              path="/channel/:id/slides" 
              element={<ChannelSlides />} 
            />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;

