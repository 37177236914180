import { useState } from 'react'
import { useSlbcContext } from '../hooks/useSlbcContext'
import FileBase64 from 'react-file-base64';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'


const ChannelSlideForm = (props) => {
  const { dispatch } = useSlbcContext()
  const {channel} = props

  const [title, setTitle] = useState('')
  const [link, setLink] = useState('')
  const [logo, setLogo] = useState('')
  const [error, setError] = useState(null)
  const [emptyFields, setEmptyFields] = useState([])

  const [loading, setLoading] = useState(false);


  const getFiles = (files) => {
    setLogo(files)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setLoading(true);

    const mainSlide = {title, channel, link, logo}
    
    const response = await fetch('/api/channels/'+channel+'/slides', {
      method: 'POST',
      body: JSON.stringify(mainSlide),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()

    if (!response.ok) {
      setError(json.error)
      setEmptyFields(json.emptyFields)
    }
    if (response.ok) {
      setEmptyFields([])
      setError(null)
      setTitle('')
      setLink('')
      setLogo('')
      dispatch({type: 'CREATE_CHANNEL_SLIDE', payload: json})
    }

    setLoading(false);

  }

  return (
    <form className="create" onSubmit={handleSubmit}> 
      <h3>Add a New Slide</h3>

      <label>Slide Title:</label>
      <input 
        type="text" 
        onChange={(e) => setTitle(e.target.value)} 
        value={title}
        className={emptyFields.includes('title') ? 'error' : ''}
      />

      <label>Link:</label>
      <input 
        type="text" 
        onChange={(e) => setLink(e.target.value)} 
        value={link}
        className={emptyFields.includes('link') ? 'error' : ''}
      />

      <label>Logo:</label>
      <FileBase64
        type="file"
        multiple={false}
        onDone={getFiles.bind(this) }
        />

      {!loading && <button>Add Slide</button>}
      {loading && <span className="material-symbols-outlined"><FontAwesomeIcon className="material-symbols-outlined" icon={icon({name: 'spinner'})} spin/></span>}

      {error && <div className="error">{error}</div>}
    </form>
  )
}

export default ChannelSlideForm