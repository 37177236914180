import { useSlbcContext } from '../hooks/useSlbcContext'

// date fns
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const ChannelSlideDetails = ({ channelSlide }) => {
  const { dispatch } = useSlbcContext()

  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteChannelSlide(channelSlide)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  const deleteChannelSlide =  async(channelSlide) => {
    setLoading(true);

    const response = await fetch('/api/channels/'+channelSlide._id+'/slides', {
      method: 'DELETE'
    })
    const json = await response.json()

    if (response.ok) {
      dispatch({type: 'DELETE_CHANNEL_SLIDE', payload: json})
      setLoading(false);
    }
  }

  return (
    <div className="workout-details slide-item">
      <img className="activator" src={'/channel_slides/'+channelSlide._id+'.png'} alt={channelSlide.title}/>
      
      <div className='channel-info'>
        <h4>{channelSlide.title}</h4>
        <p><strong>Link: </strong>{channelSlide.link}</p>
        <p>{formatDistanceToNow(new Date(channelSlide.createdAt), { addSuffix: true })}</p>
        {!loading && <span className="material-symbols-outlined" onClick={handleClick}>delete</span>}
        {loading && <span className="material-symbols-outlined"><FontAwesomeIcon className="material-symbols-outlined" icon={icon({name: 'spinner'})} spin/></span>}
      </div>
    </div>
  )
}

export default ChannelSlideDetails