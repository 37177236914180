import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ChannelSlideDetails from "../components/ChannelSlideDetail";
import ChannelSlideForm from "../components/ChannelSlideForm";
import { useSlbcContext } from "../hooks/useSlbcContext";

const ChannelSlides = (props) => {

    const {id} = useParams();
    const { channelSlides, dispatch } = useSlbcContext()
    const [channel, setChannel] = useState({});

    useEffect(() => {
        const getChannelSlides = async () => {
            const response = await fetch('/api/channels/'+id+'/slides')
            const json = await response.json()
      
            console.log(json);
      
            if (response.ok) {
              dispatch({type: 'SET_CHANNEL_SLIDES', payload: json})
            }
          }

          const getChannel = async () => {
            const response = await fetch('/api/channels/'+id)
            const json = await response.json()
      
            if (response.ok) {
                setChannel(json);
            }
          }
      
          getChannel()
          getChannelSlides()
    }, [dispatch, id]);

    return ( <div className="home">
    <div className="workouts">
        <h3>Slides for {channel && channel.title}</h3>
      {channelSlides && channelSlides.map(slide => (
        <ChannelSlideDetails channelSlide={slide} key={slide._id} />
      ))}
    </div>
    <ChannelSlideForm channel={id}/>
  </div>  );
}
 
export default ChannelSlides;