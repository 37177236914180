import { useEffect, useState } from "react"
import { useSlbcContext } from "../hooks/useSlbcContext"

// components
import ChannelDetails from "../components/ChannelDetails"
import ChannelForm from "../components/ChannelForm"
import ChannelEditForm from "../components/ChannelEditForm"

const Channels = () => {
  const { channels, dispatch } = useSlbcContext()
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [editedChannel, setEditedChannel] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchChannels()
  }, [dispatch])

  const fetchChannels = async () => {
    const response = await fetch('/api/channels')
    const json = await response.json()

    if (response.ok) {
      dispatch({type: 'SET_CHANNELS', payload: json})
    }
  }

  const handleChannelEdit = (channel) => {
      setSelectedChannel(channel);
      setEditedChannel(channel);

      console.log('editing channel');
  }

  const handleChannelUpdate = async(channel) => {
    setLoading(true);

      const response = await fetch('/api/channels/'+channel._id, {
        method: 'PATCH',
        body: JSON.stringify(channel),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      setLoading(false);
      const json = await response.json()

      fetchChannels()
      setSelectedChannel(null);
      setEditedChannel(null);
  }

  const onChannelEditCancelled = () => {
    setSelectedChannel(null);
    setEditedChannel(null);
  }

  return (
    <div className="home">
      <div className="workouts">
        {channels && channels.map(channel => (
          <ChannelDetails channel={channel} key={channel._id} handleEdit={handleChannelEdit} />
        ))}
      </div>
      {!selectedChannel && <ChannelForm />}
      {selectedChannel && <ChannelEditForm channel={editedChannel} onUpdateChannel={handleChannelUpdate} onCancel={onChannelEditCancelled} loading={loading}/>}
    </div>
  )
}

export default Channels