import { createContext, useReducer } from 'react'

export const SlbcContext = createContext()

export const slbcReducer = (state, action) => {
  switch (action.type) {
    case 'SET_WORKOUTS':
      return { 
        workouts: action.payload 
      }
    case 'CREATE_WORKOUT':
      return { 
        workouts: [action.payload, ...state.workouts] 
      }
    case 'DELETE_WORKOUT':
      return { 
        workouts: state.workouts.filter(w => w._id !== action.payload._id) 
      }
    case 'SET_CHANNELS':
      return { 
        channels: action.payload 
      }
    case 'CREATE_CHANNEL':
      return { 
        channels: [action.payload, ...state.channels] 
      }
    case 'DELETE_CHANNEL':
      return { 
        channels: state.channels.filter(w => w._id !== action.payload._id) 
      }

    case 'SET_MAIN_SLIDES':
        return { 
          mainSlides: action.payload 
        }
    case 'CREATE_MAIN_SLIDE':
      return { 
        mainSlides: [action.payload, ...state.mainSlides] 
      }
    case 'DELETE_MAIN_SLIDE':
      return { 
        mainSlides: state.mainSlides.filter(w => w._id !== action.payload._id) 
      }

    case 'SET_CHANNEL_SLIDES':
      return { 
        channelSlides: action.payload 
      }
    case 'CREATE_CHANNEL_SLIDE':
      return { 
        channelSlides: [action.payload, ...state.channelSlides] 
      }
    case 'DELETE_CHANNEL_SLIDE':
      return { 
        channelSlides: state.channelSlides.filter(w => w._id !== action.payload._id) 
      }

    default:
      return state
  }
}

export const SlbcContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(slbcReducer, { 
    workouts: null,
    channels: null,
    mainSlides: null,
    channelSlides: null,
    loading: false
  })
  
  return (
    <SlbcContext.Provider value={{ ...state, dispatch }}>
      { children }
    </SlbcContext.Provider>
  )
}