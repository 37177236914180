import { Link } from 'react-router-dom'

const Navbar = () => {

  return (
    <header>
      <div className="container">
        <img className='logo' src='/images/logo.png'/>

          <div className="nav_container">
            <Link to="/">
              <h3>Channels</h3>
            </Link>
            <Link to="/main_slides">
              <h3>Main Slider</h3>
            </Link>
          </div>
        
      </div>
    </header>
  )
}

export default Navbar