import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'


const ChannelEditForm = ({channel, onUpdateChannel, onCancel, loading}) => {

  const [editedChannel, setEditedChannel] = useState(channel)
  const [checked, setChecked] = useState(channel.status); 


  const handleSubmit = async (e) => {
    e.preventDefault()
    onUpdateChannel(editedChannel)
  }

  return (
    <form className="create" onSubmit={handleSubmit}> 
      <h3>Update Channnel</h3>

      <label>Channel Title:</label>
      <input 
        type="text" 
        value={editedChannel.title}
        onChange={(event) =>
          setEditedChannel({
            ...editedChannel,
            title: event.target.value
          })
        }
      />

      <label>Stream Link:</label>
      <input 
        type="text" 
        value={editedChannel.link}
        onChange={(event) =>
          setEditedChannel({
            ...editedChannel,
            link: event.target.value
          })
        }
      />

      <div>
        <label>Status:</label>
        <label className="switch">
          <input type="checkbox"
            value={editedChannel.status}
            defaultChecked={editedChannel.status}
            
            onChange={(event) =>{
              setChecked(!checked)
              setEditedChannel({
                ...editedChannel,
                status: checked
              })
            }}
          />
          <span className="slider round"></span>
        </label>
      </div>

      <div className="channel_form_actions">
        {!loading && <button>Update Channel</button>}
        {<button type='button' className='btn_cancel' onClick={onCancel}>Cancel</button>}
      </div>
      {loading && <span className="material-symbols-outlined"><FontAwesomeIcon className="material-symbols-outlined" icon={icon({name: 'spinner'})} spin/></span>}
    </form>
  )
}

export default ChannelEditForm