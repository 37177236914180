import { useEffect } from "react"
import MainSlideDetails from "../components/MainSlideDetail";
import MainSlideForm from "../components/MainSlideForm";
import { useSlbcContext } from "../hooks/useSlbcContext"

const MainSlider = () => {
    const { mainSlides, dispatch } = useSlbcContext()

    useEffect(() => {
        const getMainSlides = async () => {
            const response = await fetch('/api/main_slides')
            const json = await response.json()
      
            console.log(json);
      
            if (response.ok) {
              dispatch({type: 'SET_MAIN_SLIDES', payload: json})
            }
          }
      
          getMainSlides()
    }, [dispatch]);

    return ( <div className="home">
    <div className="workouts">
      {mainSlides && mainSlides.map(slide => (
        <MainSlideDetails mainSlide={slide} key={slide._id} />
      ))}
    </div>
    <MainSlideForm />
  </div> );
}
 
export default MainSlider;