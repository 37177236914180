import { SlbcContext } from "../context/SlbcContext"
import { useContext } from "react"

export const useSlbcContext = () => {
  const context = useContext(SlbcContext)

  if(!context) {
    throw Error('useSlbcContext must be used inside a SlbcContextProvider')
  }

  return context
}