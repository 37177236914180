import { useSlbcContext } from '../hooks/useSlbcContext'

// date fns
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link } from 'react-router-dom';

const ChannelDetails = ({ channel, handleEdit }) => {
  const { dispatch } = useSlbcContext()

  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteChannel(channel)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  const deleteChannel =  async(channel) => {
    setLoading(true);

    const response = await fetch('/api/channels/' + channel._id, {
      method: 'DELETE'
    })
    const json = await response.json()

    if (response.ok) {
      dispatch({type: 'DELETE_CHANNEL', payload: json})
      setLoading(false);
    }
  }

  return (
    <div className={'workout-details channel-item '+(channel.status?'active':'')}>
      {/* <img className="activator" src={channel.logo} alt={channel.title}/> */}
      <img className="activator" src={'/channels/'+channel._id+'.png'} alt={channel.title}/>
      
      <div className='channel-info'>
        <h4>{channel.title}</h4>
        <p><strong>Link: </strong>{channel.link}</p>
        <p>{formatDistanceToNow(new Date(channel.createdAt), { addSuffix: true })}</p>

        {!loading && <span className="material-symbols-outlined round_btn" onClick={handleClick}>delete</span>}
        {<span className="material-symbols-outlined edit round_btn" onClick={() => handleEdit(channel)}>edit</span>}

        
        <Link className='round_btn channel_slides' to={{ 
          pathname: `/channel/${channel._id}/slides/`, 
          state: {
            title: channel.title
          } 
        }}><span className="material-symbols-outlined" >photo_library</span></Link>

        {loading && <span className="material-symbols-outlined"><FontAwesomeIcon className="material-symbols-outlined" icon={icon({name: 'spinner'})} spin/></span>}

       
      </div>
    </div>
  )
}

export default ChannelDetails